import React from 'react'
import Fade from 'react-reveal'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

export const ContactPageTemplate = ({ title, address, phone, email, instagram }) => {
  return (
    <Fade>
      <section className="cContact">
        <div className="cContact-inner">
          <div className="cContact-content">
            <div className="cContact-header">
                <h1 className="cContact-title">
                  {title}
                </h1>
                <p className="cContact-address">{address}</p>
              </div>
              <div className="cContact-details">
                <div className="cContact-item">
                  <p className="cContact-label">phone</p>
                  <a href={`tel:+${phone && phone.replace(/\s/g, '')}`} className="cContact-link link">{phone}</a>
                </div>
                <div className="cContact-item">
                  <p className="cContact-label">info</p>
                  <a href={`mailto:${email}`} className="cContact-link link">{email}</a>
                </div>
                <div className="cContact-item">
                  <p className="cContact-label">media</p>
                  <a href={instagram && instagram.link} className="cContact-link link" target="_blank" rel="noopener noreferrer">@{instagram && instagram.handle}</a>
                </div>
              </div>
          </div>
        </div>
      </section>
    </Fade>
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  instagram: PropTypes.shape({
    handle: PropTypes.string,
    link: PropTypes.string
  })
}

const ContactPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <ContactPageTemplate
        title={post.frontmatter.title}
        address={post.frontmatter.address}
        phone={post.frontmatter.phone}
        email={post.frontmatter.email}
        instagram={post.frontmatter.instagram}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        address
        phone
        email
        instagram {
          handle
          link
        }
      }
    }
  }
`
